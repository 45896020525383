import { analytics } from "@config/firebase";
import getStripe from "./initializeStripe";
import { User } from "firebase/auth";
import { logEvent } from "firebase/analytics";

export const createCheckoutSession = async (user: User, priceId: string, monthly: boolean, credits: number) => {
  if (analytics) {
    logEvent(analytics, "checkout_started", { priceId, credits });
  }
  const response = await fetch("/api/create-checkout-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: user.uid, priceId, monthly, customer_email: user.email, credits }),
  });

  const { sessionId } = await response.json();

  // Redirect the user to the Stripe Checkout page
  const stripe = await getStripe();
  await stripe?.redirectToCheckout({ sessionId });
};
