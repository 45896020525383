import Head from "next/head";

const HeadMeta = () => {
  return (
    <Head>
      <title>eCold.ai - A.I. Cold Email Personalization in Bulk</title>
      <meta
        name="description"
        content="eCold.ai provides A.I. powered cold outreach personalization at scale with your prospects' LinkedIn URL. Increase your reply rates using our cold email icebreakers."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="eCold.ai - A.I. Cold Email Personalization in Bulk" />
      <meta
        property="og:description"
        content="eCold.ai provides A.I. powered cold outreach personalization at scale with your prospects' LinkedIn URL. Increase your reply rates using our cold email icebreakers."
      />
      <meta property="og:image" content="https://ecold.ai/link_preview.png" />
      <meta property="og:image:url" content="https://ecold.ai/link_preview.png" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:url" content="https://ecold.ai" />
      <meta property="og:site_name" content="eCold" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="eCold.ai - A.I. Cold Email Personalization in Bulk" />
      <meta
        property="twitter:description"
        content="eCold.ai provides A.I. powered cold outreach personalization at scale with your prospects' LinkedIn URL. Increase your reply rates using our cold email icebreakers."
      />
      <meta property="twitter:image" content="https://ecold.ai/link_preview.png" />
    </Head>
  );
};

export default HeadMeta;
