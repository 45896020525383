import HeadMeta from "@components/layout/Head";
import { useAuth } from "@utils/context/AuthContext";
import { useOffers } from "@utils/context/OffersContext";
import { useUserData } from "@utils/context/UserContext";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { createCheckoutSession } from "stripe_functions/createCheckoutSession";

export default function Home() {
  const { user } = useAuth();
  const { userData } = useUserData();
  const { offers } = useOffers();

  const [creatingCheckout, setCreatingCheckout] = useState(false); //Loading checkout?

  const router = useRouter();

  const handleSubscribe = async (plan: "starter" | "growth") => {
    if (!user) {
      //Fallback but shouldn't happen as userData requires user to exist
      router.push("/subscribe");
      return;
    }
    setCreatingCheckout(true);
    await createCheckoutSession(user, plan, true, 0); //TODOOOOOO
    setCreatingCheckout(false);
  };

  useEffect(() => {
    if (userData && offers.length > 0) {
      if (router.query.subscribe === "starter") {
        handleSubscribe("starter");
        return;
      } else if (router.query.subscribe === "growth") {
        handleSubscribe("growth");
        return;
      }

      //If no senderName and no first offer set, send to setup
      // if (!userData.senderName && !offers[0].valueProposition) {
      //   router.push("/setup");
      //   return;
      // }

      // if (!userData.senderName) {
      //   //Send user to set up profile
      //   router.push("/settings");
      // } else if (!offers[0].valueProposition) {
      //   //Send user to set up his offer
      //   router.push("/offers");
      // } else {
      //   //Send user to compose
      //   router.push("/compose");
      // }

      //If no subscribe, send directly to compose. Later will set up an onboarding
      router.push("/bulk");
    }
  }, [userData, offers]);

  return (
    <>
      <HeadMeta />
      <div className="flex">
        <main className="w-full h-screen p-4 bg-[#F4F8FF] flex flex-col items-center justify-center">
          {creatingCheckout && <h1 className="mb-8 font-bold text-3xl">Redirecting to checkout...</h1>}
          <span className="btn-loading-spinner w-12 h-12" />
        </main>
      </div>
    </>
  );
}
